import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
body {
  --mainColor: #45a4db;
  --backgroundColor: '#ffffff';

  --headerFont: 'Signika';
  --headerFontSize: 3rem;
  --headerFontWeight: 400;
  --headerTextColor: #45a4db;

  --mainFont: 'Open Sans';
  --mainFontSize: 14px;
  --mainFontWeight: initial;
  --mainTextColor: #2d2e2d;
  --mainTextAlign: left;

  --menuFont: 'Open Sans';
  --badgeBackgroundColor: #333;
  --badgeIconColor: #ccc;
  --gap: 4rem;

  display: grid;
  position: relative;
  font-size: var(--mainFontSize);
  z-index: 0;
  padding: 0;
  margin: 0;

  &:before {
    position: absolute;
    z-index: -1;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('${require('../../assets/bgc.jpg')}');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    opacity: 0.5;
  }

  @media (max-width: 600px) and (min-height: 736px) {
    &:before {
      height: 100vh;
      background-position-x: -150px;
    }
  }
}

#logo-area {
  grid-area: logo;
  text-align: center;
  align-self: center;
  margin-bottom: 3vh;

  img {
    max-width: 40%;
    transition: all 0.2s ease-in-out;
  }

  img:hover {
    transform: scale(1.1);
  }

  @media (max-width: 1024px) {
    img {
      max-width: 70%;
    }
  }
}

#menu-area {
grid-area: menu;
  width: 50%;
  max-width: 500px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(9em, 1fr));
  grid-template-rows: auto;
  grid-gap: 1em;
  justify-self: center;
  align-self: center;
  text-align: center;
  font-family: var(--menuFont);
  font-size: 1em;
  margin-bottom: 10vh;

  span {
    color: #fff;
    background-color: #333;
    padding: 5px;
    border-radius: 50px;
    transition: all 0.1s ease-in-out;
    text-transform: capitalize;
    align-self: center;

    &:hover {
      color: #fff;
      background-color: var(--mainColor);
      padding: 5px;
      text-shadow: none;
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}

#social-area {
  --gap: 2em;
  grid-area: social;
  display: grid;
  grid-template-columns: repeat(auto-fit, var(--gap));
  grid-template-rows: auto;
  grid-gap: 0.5em;
  justify-content: center;
  align-self: start;
  height: var(--gap);
  font-size: 1.5em;
  transition: all 0.1s ease-in-out;

  a {
    display: flex;
    background-color: var(--badgeBackgroundColor);
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    text-decoration: none;

    i {
      color: var(--badgeIconColor);
      font-size: 1em;
    }

    &:hover {
      background-color: var(--badgeIconColor);
      padding: 3px;
      text-shadow: none;
      cursor: pointer;
      transform: scale(1.1);

      i {
        color: var(--badgeBackgroundColor);
      }
    }
  }
}

.content-area {
  grid-area: paragraph;
  font-family: var(--mainFont);
  font-weight: var(--mainFontWeight);
  text-align: var(--mainTextAlign);
  margin-bottom: var(--gap);
  position: relative;

  h1 {
    grid-area: header;
    font-family: var(--headerFont);
    font-size: var(--headerFontSize);
    font-weight: var(--headerFontWeight);
    color: var(--headerTextColor);
  }
}

#main-section {
  height: 100vh;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 0.5fr 1fr auto 1fr;
  grid-template-areas:
    '.'
    'logo'
    'menu'
    'social';
}

.section-wrapper {
  position: relative;
  padding: 1em 3em;
  z-index: 2;

  &:before {
    position: absolute;
    z-index: 0;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 1;
  }
}

.fa-chevron-up {
  position: absolute;
  top: 0;
  right: 0;
  color: #999;
  cursor: pointer;
  float: right;
}
`;

export default GlobalStyle;
