import React from 'react';
import Badge from './Badge';
import GlobalStyle from './GlobalStyle';
import Logo from './Logo';

const App = () => (
  <>
    <GlobalStyle />
    <section id="main-section">
      <section id="logo-area">
        <Logo />
      </section>
      <section id="social-area">
        <Badge icon="fas fa-envelope fa-2x" link="mailto:helgi@coachhelgi.is" />
        <Badge
          icon="fab fa-facebook-f fa-2x"
          link="https://www.facebook.com/coachhelgi/"
        />
        <Badge
          icon="fab fa-instagram fa-2x"
          link="https://www.instagram.com/coach_helgi/"
        />
      </section>
    </section>
  </>
);

export default App;
